// Global packages and components
import { clsx } from 'clsx'
import { Children, cloneElement, isValidElement, useRef } from 'react'
import { Splide, SplideSlide, SplideTrack } from '@splidejs/react-splide'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline'

// Styles
import '@splidejs/react-splide/css/core'

// Types
import type { ReactNode, RefObject } from 'react'
import type { Options, Splide as SplideType } from '@splidejs/splide'

interface CarouselProps {
  children: ReactNode
  className?: string
  type?: 'slide' | 'fade' | 'loop'
  perPage?: number
  arrows?: boolean
  arrowsInline?: boolean
  dots?: boolean
  dotsColour?: 'dark' | 'light'
  options?: Options
}

// Custom progress bar
const moveProgressBar = (e: SplideType, ref: RefObject<HTMLDivElement>) => {
  const end = e.Components.Controller.getEnd() + 1
  const rate = Math.min((e.Components.Controller.getIndex() + 1) / end, 1)

  if (ref?.current) {
    ref.current.style.width = String(100 * rate) + '%'
  }
}

// Main export
const Slider = ({
  children,
  className,
  type = 'slide',
  perPage = 1,
  arrows = false,
  arrowsInline = false,
  dots = true,
  dotsColour = 'dark',
  options,
}: CarouselProps) => {
  const barRef = useRef<HTMLDivElement>(null)

  const dotsContainClass = ' !-bottom-2'

  const dotsSingleClass =
    dotsColour === 'dark'
      ? ' !bg-neutral-250 [&.is-active]:!bg-teal-green'
      : dotsColour === 'light'
        ? ' !bg-neutral-150 [&.is-active]:!bg-vibrant-green'
        : ''

  const classOptions =
    dots === true
      ? {
          classes: {
            pagination: 'splide__pagination' + dotsContainClass,
            page:
              'splide__pagination__page transition-colors duration-300' +
              dotsSingleClass,
          },
        }
      : null

  return (
    <Splide
      className={className ?? 'h-full w-full'}
      autoPlay
      hasTrack={false}
      options={{
        focus: 0,
        omitEnd: true,
        arrows: arrows,
        perPage: perPage,
        type: type,
        pagination: dots,
        mediaQuery: 'min',
        ...options,
        ...classOptions,
      }}
      onMounted={(e: SplideType) => {
        arrowsInline && moveProgressBar(e, barRef)
      }}
      onMove={(e: SplideType) => {
        arrowsInline && moveProgressBar(e, barRef)
      }}
    >
      <SplideTrack>
        {Children.map(children, child => {
          if (isValidElement(child)) {
            return (
              <SplideSlide>
                {cloneElement(child, { ...child.props })}
              </SplideSlide>
            )
          }
          return child
        })}
      </SplideTrack>

      {arrows && (
        <div
          className={clsx('splide__arrows', {
            hidden: Children.count(children) == 1,
            'splide__arrows--inline flex items-center gap-5 pt-5': arrowsInline,
          })}
        >
          {arrowsInline && (
            <>
              <div className="carousel-progress mr-3 h-0.5 w-full bg-neutral-200">
                <div
                  ref={barRef}
                  className="carousel-progress-bar h-1 w-0 -translate-y-[1px] rounded-sm bg-forest-green transition-[width] duration-500"
                />
              </div>
            </>
          )}
          <div
            className={clsx({
              'flex gap-1': arrowsInline,
            })}
          >
            <button
              type="button"
              className={clsx(
                'splide__arrow splide__arrow--prev group flex size-10 items-center justify-center rounded-full transition-all',
                {
                  'border border-teal-green bg-transparent disabled:border-neutral-300':
                    arrowsInline,
                  'absolute left-1 top-[50%] z-10 -translate-y-1/2 bg-white drop-shadow-md disabled:opacity-0 2xl:-translate-x-3/4':
                    !arrowsInline,
                }
              )}
            >
              <ChevronLeftIcon
                className={clsx('transition-color size-5 text-teal-green', {
                  'group-disabled:text-neutral-300': arrowsInline,
                })}
              />
            </button>
            <button
              type="button"
              className={clsx(
                'splide__arrow splide__arrow--next group flex size-10 items-center justify-center rounded-full transition-all',
                {
                  'border border-teal-green bg-transparent disabled:border-neutral-300':
                    arrowsInline,
                  'absolute right-1 top-[50%] z-10 -translate-y-1/2 bg-white drop-shadow-md disabled:opacity-0 2xl:translate-x-3/4':
                    !arrowsInline,
                }
              )}
            >
              <ChevronRightIcon
                className={clsx('transition-color size-5 text-teal-green', {
                  'group-disabled:text-neutral-300': arrowsInline,
                })}
              />
            </button>
          </div>
        </div>
      )}
    </Splide>
  )
}

export default Slider
