import { TrustpilotWidgetStyleProps } from '~/types'

export const getTrustpilotWidgetStyle = (
  templateId?: string
): TrustpilotWidgetStyleProps => {
  switch (templateId) {
    case 'carousel':
      return {
        template: '53aa8912dec7e10d38f59f36',
        height: '140px',
        width: '100%',
        stars: '5',
      }
    case 'mini':
      return {
        template: '5419b6ffb0d04a076446a9af',
        height: '42px',
        width: '210px',
        stars: '4,5',
      }
    case 'product':
      return {
        template: '5717796816f630043868e2e8',
        height: '500px',
        width: '100%',
        stars: '1,2,3,4,5',
      }
    case 'product-mini':
      return {
        template: '54d39695764ea907c0f34825',
        height: '24px',
        width: '190px',
        stars: '1,2,3,4,5',
      }
    default:
      return {
        template: '5419b6ffb0d04a076446a9af',
        height: '42px',
        width: '100%',
        stars: '4,5',
      }
  }
}
