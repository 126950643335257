import clsx from 'clsx'
import { useRef, useEffect, useState } from 'react'
import { getTrustpilotWidgetStyle } from '~/utils'
import { LoaderIcon } from '~/components'

interface Props {
  templateId?: 'carousel' | 'mini' | 'product' | 'product-mini'
  product?: { sku: string; name?: string }
}

const Trustpilot = ({ templateId, product }: Props = {}) => {
  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null)

  // Local state
  const [activeSku, setActiveSku] = useState<string | null>(
    product?.sku ?? null
  )
  const [loaded, setLoaded] = useState<boolean>(false)

  // Get the styling for each individual widget
  const { template, height, width, stars } =
    getTrustpilotWidgetStyle(templateId)

  // Loads the trustpilot widget
  const loadElement = () => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }

  // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
  // If it's not, it means the script you pasted into <head /> isn't loaded  just yet.
  // When it is, it will automatically load the TrustBox.
  useEffect(() => {
    if (!loaded) {
      loadElement()
      setLoaded(true)
    }
  }, [loaded])

  // When product sku changes, refresh the widget
  useEffect(() => {
    if (product?.sku && product.sku !== activeSku) {
      setActiveSku(product.sku)
      setLoaded(false)
    }
  }, [activeSku, product])

  return (
    <div
      ref={ref}
      data-template-id={template}
      data-style-height={height}
      data-style-width={width}
      data-sku={product?.sku}
      data-name={product?.name}
      data-stars={stars}
      className={clsx('trustpilot-widget', {
        'origin-left scale-75 sm:scale-100': templateId == 'product-mini',
      })}
      data-style-alignment="left"
      data-locale="en-GB"
      data-businessunit-id="4bebbd1c00006400050b13eb"
      data-review-languages="en"
      data-font-family="Poppins"
      data-text-color="#232323"
      data-no-reviews="show"
      data-fullwidth="true"
    >
      <a
        href="https://uk.trustpilot.com/review/www.hss.com"
        target="_blank"
        rel="noopener noreferrer"
        className="flex w-full items-center justify-center"
      >
        <LoaderIcon size="xs" />
      </a>
    </div>
  )
}
export default Trustpilot
